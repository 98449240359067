// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-card-container {
  margin-bottom: 15px;
}
.info-card-container .info-card-title {
  font-size: 110%;
}
.info-card-container .info-card-time-place {
  font-size: 80%;
  margin-bottom: 5px;
  color: var(--textColorDark);
  transition: 0.1s color ease-in;
}
.info-card-container .info-card-time-place div {
  display: inline-block;
}
.info-card-container .info-card-time-place .time {
  padding-right: 10px;
  border-right: 2px solid var(--colorHighlight);
}
.info-card-container .info-card-time-place .place {
  padding-left: 10px;
}
.info-card-container .info-card-short {
  font-size: 90%;
  color: var(--textColorSecondary);
  transition: 0.1s color ease-in;
}`, "",{"version":3,"sources":["webpack://./src/app/components/info-card/info-card.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,cAAA;EACA,kBAAA;EACA,2BAAA;EACA,8BAAA;AAAJ;AAEI;EACE,qBAAA;AAAN;AAGI;EACE,mBAAA;EACA,6CAAA;AADN;AAII;EACE,kBAAA;AAFN;AAME;EACE,cAAA;EACA,gCAAA;EACA,8BAAA;AAJJ","sourcesContent":[".info-card-container {\n  margin-bottom: 15px;\n\n  .info-card-title {\n    font-size: 110%;\n  }\n\n  .info-card-time-place {\n    font-size: 80%;\n    margin-bottom: 5px;\n    color: var(--textColorDark);\n    transition: 0.1s color ease-in;\n\n    div {\n      display: inline-block;\n    }\n\n    .time {\n      padding-right: 10px;\n      border-right: 2px solid var(--colorHighlight);\n    }\n\n    .place {\n      padding-left: 10px;\n    }\n  }\n\n  .info-card-short {\n    font-size: 90%;\n    color: var(--textColorSecondary);\n    transition: 0.1s color ease-in;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
